import React from "react";

const HeroContent: React.FunctionComponent = () => {
    return (
        <div className="hero__content container mx-auto">
            <div className="col-lg-6 px-0">
                <a name="about" /><h1 className="hero__title mb-3">Financial desktop <span className="highlight">transformation</span> strategy.</h1>
                <p className="hero__paragraph">White Dog provides a niche consultancy service, focussed on transformation of the desktop application user experience in financial services. We're experts in <a href="https://fdc3.finos.org" title="Financial Desktop Connectivity and Collaboration Consortium">FDC3</a> (open standards for the financial desktop), and have experience using the major container vendors.</p>
                <p className="hero__paragraph">Get in touch to find out more.</p>
                <div className="col-lg-10 col-xl-9 mx-0 p-0" />
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0 px-0 mx-0">
                <div className="hero__img-container"><img src="./laptop.jpg" className="hero__img w-100" /></div>
            </div>
        </div>
    );
}

export default HeroContent;