import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

// declare global {
//     interface Window {
//         grecaptcha: any;
//     }
// }

// const grecaptchaObject = window.grecaptcha;

const Contact: React.FunctionComponent = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const onSubmit = async e => {
        e.preventDefault();
        const params = new URLSearchParams(new FormData(e.target));

        const token = await recaptchaRef.current.execute();
        if (!token) {
            return;
        }

        params.append("token", token);

        await fetch("https://api.whitedog.io/website/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: params
        });
    }

    const recaptchaRef = useRef();

    return (
        <div className="block-28">
            <div className="container">
                <div className="row h-100">
                    <div className="col-lg-6 h-100 block-28__content-side">
                        <div className="contact-info">
                            <h5 className="contact-info__title mb-3">Get in Touch</h5>
                            <div className="mb-5">
                                <p className="d-flex flex-column">
                                    <span className="contact-info__item mb-2"><a href="mailto:hello@whitedog.io"><FontAwesomeIcon icon={faEnvelope} /> hello@whitedog.io</a></span>
                                    <span className="contact-info__item mb-2"><a href="tel:+443300438423"><FontAwesomeIcon icon={faPhone} /> +44 (0) 330 043 8423</a></span>
                                    <span className="contact-info__item mb-2"><a href="tel:+16462593817"><FontAwesomeIcon icon={faPhone} /> +1 646 259 3817</a></span>
                                    <span className="contact-info__item mb-2"><a href="https://github.com/whitedogio"><FontAwesomeIcon icon={faGithub} /> github/whitedogio</a></span>
                                    <span className="contact-info__item"><a href="https://twitter.com/whitedogio"><FontAwesomeIcon icon={faTwitter} /> twitter/whitedogio</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 h-100 block-28__form-side">
                        <form onSubmit={e => onSubmit(e)} className="contact-form text-center">
                            <div className="contact-form__header mb-5">
                                <a name="contact" /><h6 className="contact-form__title mb-3">Contact Us</h6>
                            </div>
                            <input type="text" className="contact-form__input" placeholder="Full Name" name="name" value={name} onChange={e => setName(e.target.value)} />
                            <input type="email" className="contact-form__input" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                            <textarea className="contact-form__input" placeholder="Message" name="message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LdRPdQZAAAAABIYirX8Gj3hWwickvw9P6aU-LGt" />
                            <Button type="primary" className="w-100">Send Message</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;