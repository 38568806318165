import React from "react";
import Articles from "../components/articles";
import Contact from "../components/contact";
import Footer from "../components/footer";
import HeroContent from "../components/heroContent";
import Navigation from "../components/navigation";

const Home: React.FunctionComponent = () => {
  return (
    <>
      <header className="hero container-fluid">
        <Navigation />
        <HeroContent />
      </header>
      {/* <Articles /> */}
      <Contact />
      <Footer />
    </>
  );
}

export default Home;